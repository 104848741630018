import classNames from "classnames";

export default function Maintenance() {
  return (
    <div className="prose text-center mx-auto flex flex-col items-center justify-center h-screen p-6">
      <MaintenanceMessage />
      <p>We&apos;ll be back online shortly. Thank you for your patience!</p>
    </div>
  );
}

const MAINTENANCE_WINDOW = "Wednesday, 30 Oct, from 10pm to 11pm SGT";

const MaintenanceMessage = ({ concise = false }) => (
  <p>
    We&apos;re upgrading our systems to enhance reliability and will undergo
    scheduled maintenance on{" "}
    <span className="font-bold">{MAINTENANCE_WINDOW}</span>.
    {!concise && (
      <>
        {" "}
        Our website will be temporarily unavailable during this time. We
        appreciate your understanding as we work to serve you better.
      </>
    )}
  </p>
);

export const MaintenanceBanner = ({ concise = false }) => (
  <div className="bg-dark-purple p-3.5 opacity-90">
    <div
      className={classNames(
        "prose text-center text-sm mx-auto whitespace-pre-line",
        "text-white font-medium"
      )}
    >
      <MaintenanceMessage concise={concise} />
    </div>
  </div>
);
